<template>
  <div class="card p-2 mx-2">
    <label class="mx-1"><i id="coupon-help" class="fa fa-question-circle"/>
      {{ codeLabel }}<span v-if="user?.firstName"> - {{ user?.firstName }} {{ user?.lastName }}</span>
    </label>
    <b-form-input type="text" v-model="couponCode" @input="makeInit" class="form-control my-2" :placeholder="statusLabel"/>
    <b-form-text v-if="couponSubmit && isCouponState === 'valid'" :dir="lang==='en' ? 'ltr': ''">
      {{$t("booking.valid-message")}} {{couponDiscountAmount}} {{$t(`booking.coupon-currency-symbol-${currency}`)}}
    </b-form-text>
    <b-form-text v-if="couponSubmit && isCouponState === 'invalid'">
      {{couponErrorMessage || $t("booking.invalid-message")}}
    </b-form-text>
    <b-form-text v-if="couponSubmit && isCouponState === 'validother'">
        {{$t("booking.valid-message-other")}}
    </b-form-text>
    <b-form-text v-if="couponSubmit && isCouponState === 'error'">
      {{couponErrorMessage}}
    </b-form-text>
    <b-form-text v-if="isDuplicate">
      {{$t('booking.duplicate-message')}}
    </b-form-text>
    <button class="btn btn-primary form-control my-2" @click="submitWithCoupon" :disabled="isDuplicate || couponSubmit || isCouponSubmitting">
      {{ $t("booking.submit") }}
    </button>
    <b-tooltip target="coupon-help" :title="$t('booking.coupon-help')"></b-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormInput, BFormText, BTooltip } from 'bootstrap-vue';

export default {
  components: {
    BFormInput,
    BFormText,
    BTooltip,
  },
  props: {
    userIndex: {
      type: Number,
      default: 0,
    },
    numOfPax: {
      type: Number,
      default: 0,
    },
    checkInDate: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: 'US',
    },
    originalPrice: {
      type: Number,
      default: 0,
    },
    oldShekelPrice: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      default: null,
    },
    couponCodes: {
      type: Array,
      default: () => [],
    },
    couponLabels: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      isCouponSubmitting: 'GET_IS_COUPON_SUBMITTING_STATE',
    }),
    isDuplicate() {
      const { couponCode, couponCodes, userIndex } = this;
      return couponCodes.findIndex((code, inx) => inx !== userIndex && code === couponCode) > -1;
    },
    codeLabel() {
      return this.couponLabels?.codeLabel ?? this.$t('booking.coupon-label');
    },
    statusLabel() {
      return this.isMaccabiAgency ? this.$t('login.enter-employee-number') : this.couponLabels?.statusLabel ?? this.$t('booking.no-coupon');
    },
  },
  data() {
    return {
      couponDiscountAmount: '',
      couponSubmit: false,
      couponValue: 0,
      amountCouponAfterDiscount: 0,
      amountCouponAfterDiscountNis: 0,
      isCouponState: 'unset', // valid, invalid, error, unset
      couponCode: '',
      couponErrorMessage: '',
    };
  },
  methods: {
    makeInit() {
      this.couponSubmit = false;
      this.isCouponState = 'unset';
      this.couponDiscountAmount = 0;
    },
    async submitWithCoupon() {
      if (this.couponCode.trim() === '' || this.isDuplicate || this.isCouponSubmitting) return;
      const { originalPrice, checkInDate, currency } = this;
      const body = { code: this.couponCode, originalPrice, checkInDate, currency, numOfPax: this.numOfPax };

      // init coupon variable
      this.couponSubmit = true;
      this.isCouponState = 'unset';
      this.couponDiscountAmount = 0;

      const response = await this.$store.dispatch('FETCH_COUPON_INFO', body);
      if (response.status === 400 || response.status === 500) {
        this.isCouponState = 'error';
        this.couponErrorMessage = this.$t(response.errors.field[0]);
        this.amountCouponAfterDiscount = 0;
        this.amountCouponAfterDiscountNis = 0;
      } else {
        this.couponValue = response.amountAfterDiscount;
        this.amountCouponAfterDiscount = response.available ? response.amountAfterDiscount : this.originalPrice;
        this.amountCouponAfterDiscountNis = response.available ? response.amountAfterDiscountNis : this.oldShekelPrice;
        this.isCouponState = response.available ? 'valid' : 'invalid';
        if (response.codeAgent === 'LAWYERS') { this.isCouponState = 'validother'; }
        this.couponCode = response.couponCode;
        this.couponErrorMessage = response.remark;
        this.couponDiscountAmount = response.discountAmount;

        if (response.available) {
          this.$emit('updateCouponList', {
            inx: this.userIndex,
            code: this.couponCode,
            discountAmount: this.originalPrice - this.amountCouponAfterDiscount,
            discountAmountNis: this.oldShekelPrice - this.amountCouponAfterDiscountNis,
          });
        }
      }
    },
  },
};
</script>

<style>

</style>
